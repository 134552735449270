<template>
  <div class="noData" :class="classObj">
    <img :src="data.img" alt="" class="uiIcon" />
    <div v-if="data.des" class="des">{{ data.des }}</div>
    <div v-if="data.buttom" class="buttom">{{ data.buttom }}</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'NoData',
  components: {},
  props: {
    type: {
      type: String,
      default: 'search',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: {
        img: require('@/assets/common/noDataSearch.png'),
        des: '没有搜索到结果',
        buttom: '发布需求',
      },
      content: {
        img: require('@/assets/common/noDataContent.png'),
        des: '暂时没有相关内容',
      },
    }
  },
  computed: {
    data() {
      return this[this.type]
    },
    classObj() {
      return {
        mediumNoData: this.size === 'medium',
      }
    },
  },
  methods: {},
}
</script>

<style scoped lang="less">
// @import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .uiIcon {
    width: 250px;
    height: auto;
  }
  .des {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
.mediumNoData {
  .uiIcon {
    width: 125px;
    height: auto;
  }
}
</style>
