<template>
  <div class="container">
    <Breadcrumb :data="breData" :is$t="true" />
    <div class="content">
      <Search
        ref="search"
        class="search"
        :search-info="searchInfo"
        @search="getData"
      />
      <div v-if="data.length">
        <div class="listWarp">
          <div
            v-for="(item, index) of data"
            :key="item.id"
            class="listItem"
            @click="jumpDetails(item.id)"
          >
            <img class="cover" :src="item.logo" alt="" />
            <div class="title">{{ item.name }}</div>
            <div class="hotTag">人气：{{ item.popularity || 0 }}</div>
          </div>
        </div>

        <Pagination
          class="pagination"
          :page-info="pageInfo"
          @changePage="changePage"
        />
      </div>
      <NoData v-else type="content" size="medium" class="no-data" />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import NoData from "@/components/NoData"
import Search from "@/components/Search"
import Breadcrumb from "@/components/Breadcrumb"
import API from "@/api/lawServiceProduct"
export default {
  name: "ServiceProductList",
  components: {
    Breadcrumb,
    Pagination,
    NoData,
    Search,
  },
  data() {
    return {
      searchInfo: {
        sourceType: "LEGAL_PRODUCTS",
      },
      breData: [
        { name: "首页", path: "/home" },
        { name: "法律服务产品", path: "/lawServiceProduct/list" },
      ],
      data: [],
      pageInfo: {
        page: 1,
        size: 8,
        total: 0,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(isSearch) {
      this.loading = true
      const {
        pageInfo: { size, currentPage: page },
      } = this
      API.getLegalServiceProductPage({
        size,
        page: isSearch ? 1 : page,
        name: this.searchInfo.keyword,
      }).then((res) => {
        this.data = res.content
        this.loading = false
        this.pageInfo.total = res.page.total
      })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getData()
    },
    jumpDetails(id) {
      if (id) {
        API.postLegalServiceProduct(id)
        this.$router.push({
          path: `/lawServiceProduct/details?id=${id}`,
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.container {
  width: @uni-width;
  margin: auto;
}
.content {
  width: @uni-width;
  margin: 0 auto 20px;
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  padding: 30px;
  .search {
    margin: 0 !important;
  }
  .listWarp {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    min-height: calc(100vh - 446px);
    .listItem {
      width: 230px;
      min-width: 230px;
      height: 200px;
      background: #ffffff;
      padding: 20px;
      margin-right: 30px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 35px 0px rgba(176, 176, 176, 0.27);
      position: relative;
      cursor: pointer;
      .cover {
        width: 230px;
        height: 160px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .hotTag {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        padding: 8px;
        background: linear-gradient(324deg, #faad5d 0%, #fc7753 100%);
        border-radius: 4px 0px 4px 0px;
      }
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .no-data {
    min-height: calc(100vh - 446px);
    justify-content: center;
  }
}
</style>
