<template>
  <div class="search">
    <div v-if="this.$route.path.includes('/service/lawyer')" class="switch">
      <div
        v-for="(item, index) of searchTypeData"
        :key="index"
        :class="{ active: searchTypeIndex === index, uiText: index === 0 }"
        @click="changeSearchType(item, index)"
      >
        {{ $t(item.content) }}
      </div>
    </div>
    <div class="container">
      <!-- <div class="country">
        <el-select
          v-model="searchInfo.countryId"
          placeholder="请选择"
          class="selectContainer"
          @change="changeProvince"
        >
          <el-option
            v-for="item in dictionaryList.content"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div> -->
      <div class="context">
        <input
          v-model="searchInfo.keyword"
          class="searchInput"
          type="text"
          :placeholder="placeholder"
        />
        <span class="inputPrefix">
          <i class="el-input__icon el-icon-search"></i>
        </span>
      </div>
      <div class="searchBuuton" type="primary" @click="search">
        {{ $t("3a3636a") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import API from "@/api";
export default {
  name: "Search",
  components: {},
  props: {
    searchInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchTypeIndex: 0,
      searchTypeData: [
        {
          content: "b80d75a",
          type: "LAW_OFFICE",
        },
        {
          content: "1bba550",
          type: "LAWYER",
        },
      ],
    };
  },
  computed: {
    // ...mapGetters(['countryList', 'provinceList', 'dictionaryList']),
    // ...mapGetters(['dictionaryList']),
    placeholder() {
      const type = this.searchInfo.sourceType;
      const obj = {
        LAW_OFFICE: "4e0d517",
        LAWYER: "1b17144",
        ARBITRATION_BOARD: "290aa07",
        CONCILIATION_BOARD: "d6a3ac0",
        NOTARY_OFFICE: "061fc33",
        JUDICIAL_EXPERTISE: "3d75c90",
        LAW_HELPER: "2b245f7",
        LEGAL_PRODUCTS: "4e0d518",
      };
      return this.$t(obj[type]);
    },
  },
  created() {
    // this.getData()
  },
  methods: {
    // ...mapActions(['getCountryList', 'getProvinceList']),
    getData() {
      // this.getCountryList(0)
      // console.log(`1111`, 'xxxx')
      // this.getProvinceList({ parentId: this.searchInfo.provinceId })
    },
    search() {
      this.$emit("search", true);
    },
    changeSearchType(item, index) {
      this.searchTypeIndex = index;
      this.searchInfo.keyword = "";
      this.searchInfo.sourceType = item.type;
      this.search();
    },
    changeProvince() {
      this.getProvinceList({ parentId: this.searchInfo.provinceId });
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
@import "~@/styles/common.less";
.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 20px;
  .switch {
    display: flex;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    color: @uni-text-color-select;
    .uiText {
      margin-right: 20px;
    }
    div {
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .active {
      position: relative;
      background: @uni-text-color-select;
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -12px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #cccccc;
      }
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -13px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #fff;
        z-index: 1;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    width: @uni-width;
    margin-bottom: 20px;
  }
  .country {
    .selectContainer {
      & /deep/ .el-input__inner {
        height: 48px;
        border: 1px solid #ccc;
        border-right: 1px solid #ddd;
        border-radius: 4px 0 0 4px;
      }
    }
  }
  .context {
    position: relative;
    width: 100%;
  }
  .searchInput {
    .uni-input;
    width: 100%;
    border: 1px solid #ccc;
    // border-left: none;
    border-radius: 0 4px 4px 0;
  }
  .inputPrefix {
    position: absolute;
    top: 0;
    left: 5px;
    transition: all 0.3s;
    height: 100%;
    color: #bdbdbd;
    text-align: center;
  }
  .searchBuuton {
    width: 118px;
    height: 48px;
    margin-left: 10px;
    background: #00a4ff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
